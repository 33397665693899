import React, { useState, useEffect } from 'react';
import FileViewer from 'react-file-viewer';
import LoadingSpinner from '../LoadingSpinner'; // Your custom spinner component
import './AttachmentRenderers.css';

const DocxFileViewer = ({ fileType, url }) => {
    const [loading, setLoading] = useState(true);
    const [isFileReady, setIsFileReady] = useState(false);

    useEffect(() => {
        setLoading(true);
        setIsFileReady(false);

        // Create a temporary request to check if the file URL is accessible
        const checkFile = async () => {
            try {
                const response = await fetch(url, { method: 'HEAD' });
                if (response.ok) {
                    setIsFileReady(true); // File is accessible
                } else {
                    throw new Error('File not accessible');
                }
            } catch (error) {
                console.error('Error loading file URL:', error);
            } finally {
                setLoading(false);
            }
        };

        checkFile();
    }, [url]);

    const handleError = (error) => {
        console.error('Error displaying file:', error);
        setLoading(false); // Stop loading spinner if an error occurs
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            <div className='attachmentsContainer'>
                    <FileViewer
                        fileType={fileType}
                        filePath={url}
                        onError={handleError}
                    />
            </div>
        </>
    );
};

export default DocxFileViewer;
