import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './AttachmentRenderers.css';
import LoadingSpinner from '../LoadingSpinner';

const CsvXlsxViewer = ({ url, fileType }) => {
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        setLoading(true); 
        fetch(url)
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
                const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                if (data.length > 0) {
                    const columns = data[0].map((header, index) => ({
                        headerName: header || `Column ${index + 1}`,
                        field: `column${index}`,
                        resizable: true,
                    }));
                    setColumnDefs(columns);

                    const rows = data.slice(1).map((row, rowIndex) => {
                        return row.reduce((acc, cell, index) => {
                            acc[`column${index}`] = cell;
                            return acc;
                        }, { id: rowIndex });
                    });
                    setRowData(rows);
                } else {
                    setRowData([]); 
                }
                setLoading(false); 
            })
            .catch((error) => {
                console.error('Error loading XLSX/CSV file:', error);
                setLoading(false); 
            });
    }, [url, fileType]);

    return (<>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className="ag-theme-alpine attachmentsContainer">

                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        filter: true,
                    }}
                    domLayout="autoHeight"
                    suppressRowVirtualisation={false}
                    noRowsOverlayComponentFramework={() => (
                        <div>No data available</div>
                    )}
                />
                </div>
            )}
            </>
    );
};

export default CsvXlsxViewer;
