import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button } from '@cmsgov/design-system';
import CsvXlsxViewer from './AttachmentRenderers/CsvXlsxViewer';
import DocxFileViewer from './AttachmentRenderers/DocxFileViewer';
import { fileRenderers } from '../../../common/constants';

const AttachmentPartPDF = ({ url, fileType, commentId, filterText, hasText, setShowPdf, attNum, office, machine }) => {


    const txtButton = hasText ? (
        <Button className='comments_button_primary_outline' tabIndex="0" size="small" onClick={setShowPdf}>
            Show Text and Modeling Data
        </Button>
    ) : (
        <Button className='comments_button_primary_outline' tabIndex="0" aria-label="Text and Modeling Data unavailable for this attachment" size="small" disabled>
            Show Text and Modeling Data
        </Button>
    );

    // Determine the content based on the file type
    let content;

    if (fileRenderers.iframe.includes(fileType)) {

        content = (
            <iframe
                id="file-viewer-iframe"
                src={url}
                title={`${fileType} viewer`}
                style={{ height: '900px', width: '100%' }}
                allowFullScreen
                frameBorder="1"
            />
        );
    } else if (fileRenderers.docxViewer.includes(fileType)) {
        const normalizedFileType = fileType.replace(/^\.+/, '');
        content = <DocxFileViewer fileType={normalizedFileType} url={url} />;
    } else if (fileRenderers.sheetViewer.includes(fileType)) {
        content = <CsvXlsxViewer url={url} fileType={fileType} />;
    } else {
        return <div>{`${fileType} is not supported.`}</div>;
    }

    return (
        <>
            <Row className="comment-border-dotted-bottom comment-border-no-bottom">
                <Col md={3}>{txtButton}</Col>
                <Col>
                    <div style={{ padding: '8px' }}>
                        <b>
                            {commentId} Attachment: {attNum}
                        </b>
                    </div>
                </Col>
            </Row>
            {content}
        </>
    );
};

export default AttachmentPartPDF;
